
import Compressor from 'compressorjs';


export const toBase64 = (file: File | Blob) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});
export function Privilege(n: number) {

  if (n < 5) {
    return "Técnico"
  }


  return "Administrador"
}
export function Compress(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.8,
      height: 250,
      width: 290,
      resize: "cover",

      mimeType: 'image/jpeg',
      async success(result) {
        const base64 = await toBase64(result) as string;
        resolve(base64)

      },
      error(err) {
        throw new Error("Failed to compress image");
      },
    })
  })

}
export function BuildQuery({ skip, take, filter }: { skip: number, take: number, filter?: string }) {
  return `?skip=${skip}&take=${take}&filter=${filter}`
}

export function percentage(x: number, y: number) {
  return 100 / (y / x)
}

export function minutes(ms: number) { return 1000 * 60 * ms }
export function seconds(ms: number) { return 1000 * ms }





const Colors = [
  "#fff",
  "#8a8d90",
  "#8cc4fb",
  "#3c99f4",
  "#587a9c",
  "#b08fcd",
  "#a269d3",
  "#8527d5",
  "#d1e19b",
  "#c2db6e",
  "#b2d92e",
  "#a7d40f",
  "#d4690f",
  "#df8941",
  "#d8aa84",
  "#b6a495",
  "#f7e616",
  "#baad0d",
  "#f9ee5f",
  "#b3ac4f",
  "#9d9a6e",
  "#ff8383",
  "#f0acac",
  "#ff0000",
  "#72ff00",
  "#00ff99",
  "#00c9ff",
  "#48d5fb",
  "#9eeaff",
  "#668e99",
  "#fcff00",
  "#ff00f4",
  '#D180DA', '#67C0F7', '#6D670D', '#683C47', '#A23A8C', '#FD6984',
  '#744D20', '#D229A1', '#014561', '#FC9BDA', '#AFF91D', '#A26A84',
  '#3664AB', '#DC8677', '#21A327', '#590056', '#710234', '#9ACD8C',
  '#0A6D10', '#16C998', '#B9DDF4', '#BCCD78', '#A8F0BA', '#0803F1',
  '#6225D7', '#0DB192', '#977817', '#0062D4', '#5CCD8D', '#18A19F',
  '#17F461', '#704273', '#8A4AD7', '#CDF655', '#645D7F', '#A58926',
  '#B2308F', '#C31F64', '#858200', '#767642', '#DB1053', '#7F6AD7',
  '#0F07D5', '#1AC31C', '#0A2937', '#9540CC', '#3697C8', '#92207F',
  '#15F786', '#2200DB', '#640BD2', '#931A21', '#207900', '#87F227',
  '#DB16CD', '#53B3BF', '#907C58', '#F3DFA7', '#2D53DF', '#B7767C',
  '#B19FB8', '#59F6A2', '#C5B525', '#275B8B', '#8B5C86', '#14D970',
  '#56C8B9', '#F89B8C', '#D27D11', '#4F78DC', '#C4DC94', '#84B8C1',
  '#373445', '#7594F0', '#1B3D57', '#7DBFD3', '#482FCC', '#3B6D58',
  '#356123', '#8CA9AD', '#D34CF3', '#617702', '#3D9FF9', '#F6CD10',
  '#763709', '#2D6B82', '#72891C', '#5601A3', '#7D106D', '#6D8F93',
  '#B452BD', '#257B21', '#0B7812', '#AC002B', '#0834A1', '#9091FC',
  "#ed3de5",
  "#ffa2fb",
  "#8d0087",
  '#1DD270', '#22837D', '#76AB98', '#2A33FA', '#983C35', '#FC2A72',
  '#7F9BA1', '#CC7ADF', '#F46687', '#8469CF', '#38A1CB', '#18B01A',
  '#9FD8BD', '#84736D', '#C9A663', '#3378C0', '#C8670F', '#BBAC2F',
  '#97ABF1', '#370342', '#231CF0', '#07C56D', '#9B4A50', '#CBC06A',
  '#F35080', '#031485', '#60C905', '#9308A5', '#95ACC0', '#67380D',
  '#D55C54', '#F7DFF3', '#AF5F4D', '#9524C0', '#8DFF8F', '#52F2F6',
  '#866AB9', '#694510', '#A391AA', '#532D17', '#42545B', '#8439DB',
  '#08BB6C', '#39C593', '#0D8959', '#201B04', '#3ACC44', '#D60F75',
  '#DAFBF7', '#A034C4', '#FD24B9', '#5B597A', '#3B63CC', '#031DD7',
  '#A23A84', '#48684D', '#CF6688', '#59568C', '#D43053', '#8C7BB6',
  '#512A77', '#A19312', '#1C9DD5', '#5742AA', '#A1FC49', '#007251',
  '#A83BF0', '#78D28D', '#55F078', '#4831A6', '#B50012', '#C1D6DB',
  '#726469', '#A51773', '#C535DD', '#661496', '#375B4B', '#467507',
  '#35B085', '#51C1D9', '#A07FB5', '#A39B1B', '#684908', '#A71944',
  '#D96F8C', '#8CB37B', '#9CCB9C', '#8D6D94', '#0F6043', '#0FDF86',
  '#20A817', '#C50F75', '#4F8FBC', '#FDC3F9', '#A9569A', '#B88A0B',
  "#008d15",
  "#4b6d50",
  "#000000",
]
export function getRandomColor() {

  return Colors[Math.floor(Math.random() * Colors.length)];
}