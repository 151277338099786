import {
  Search2Icon
} from '@chakra-ui/icons';
import {
  Avatar, Badge, Box, Button, Container, Flex, Heading, HStack, Image, Input,
  InputGroup,
  InputRightAddon, SimpleGrid, Spinner, Stack, StackDivider, Text, useColorModeValue
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { useEffect, useState } from "react"; 
import { config } from '../../config/config';
import { useDebounce } from '../../hooks/useDebounce';
import { api } from "../../services/api";
import { Relatorio } from "../../services/relatorio";
import { BuildQuery } from '../../utils';
export function ListarRelatorio() {

  const [relatorios, setRelatorios] = useState<Relatorio[]>()
  const [search, setSearch] = useState("")
  const debouncedSearch = useDebounce(search, 500)
  const [offset, setOffset] = useState(0)
  const [isLoading, setIsLoading] = useState(false)


  useEffect(() => {

    (async () => {
      try {
        setIsLoading(true)
        const query = BuildQuery({ skip: debouncedSearch ? 0 : offset, take: 15, filter: debouncedSearch })

        const { data } = await api.get(`/relatorio${query}`)
        setRelatorios((relatorios) => {
          if (search) {
            return data
          }

          const current = relatorios ?? []
          return [...current, ...data]
        })
      } catch (error) {

      }
      finally {
        setIsLoading(false)
      }
    })();
  }, [offset, debouncedSearch])

  if (!relatorios) return <>Carregando, pode levar alguns segundos</>

  const x = relatorios


  return (
    <Container maxW={'5xl'} py={8}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Flex w='full' justify={'center'}>
            <Badge colorScheme={'green'} textAlign='center' px={2} w='max-content'>
              ONLINE
            </Badge>
          </Flex>
          <Heading textAlign={"center"}>Relatórios Prontos</Heading>
          <Text color={useColorModeValue('gray.500', 'gray.300')} fontSize={'lg'}>
            Escolha o relatório que deseja renderizar para PDF.

          </Text>

          <InputGroup>
            <Input
              placeholder="Buscar por nome ou ID do equipamento"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              variant="filled"
              width="100%"
              borderRadius={5}
              mb={3}

            />
            <InputRightAddon children={
              isLoading ?   <Spinner /> : <Search2Icon />
            } />

          </InputGroup>

          <Stack
            spacing={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')}
              />
            }>

            <Stack
              spacing={4}
              divider={
                <StackDivider
                  borderColor={useColorModeValue("gray.100", "gray.700")}
                />
              }>
              {x?.map((relatorio, i) => (
                <Flex
                  cursor="pointer"
                  onClick={() => {
                    window.open("https://api.hplelevadores.com.br/v1/render/" + relatorio.id, "_blank")
                  }}
                  key={`card-offline-22mq44-${i}`}
                  _hover={{
                    bg: useColorModeValue("gray.50", "gray.900"),
                  }}
                  gap={4}

                >
                  <Flex position={`relative`} w='160px'>
                    <Image src={relatorio?.hero} h='100%' maxH={`80px`} w="100%" objectFit={"fill"} borderRadius={5} opacity={1} />
                    <Avatar
                      h={31}
                      w={31}
                      src={`${config.s3bucket}/user-${relatorio.user_id}`}
                      position={`absolute`}
                      bottom={`-0px`}
                      right={`-10px`}
                    />
                  </Flex>

                  <Box w={'full'}>
                    <Text fontWeight={'semibold'} noOfLines={1}>
                      {relatorio.name}
                    </Text>
                    <Text noOfLines={2}>
                      {relatorio.inspecao.map(inspecao => inspecao.equipamento_id).join(", ")}
                    </Text>
                    <Badge colorScheme={'gray'}>

                      <Text fontSize={'xs'}>
                        {dayjs(relatorio.finishedAt).format('DD/MM/YYYY HH:mm:ss')}
                      </Text>
                    </Badge>
                  </Box>
                </Flex>
              ))}

            </Stack>
 
            <HStack>
              <Flex w='full'>

                <Button w='full' colorScheme={'green'} 
                  isLoading={isLoading}
                  onClick={() => {
                    setOffset(offset + 15)
                  }}>
                  Carregar mais
                </Button>
              </Flex>
            </HStack>


          </Stack>
        </Stack>

      </SimpleGrid>
     
    </Container>
  );
}